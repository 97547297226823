<template>
  <div>
    <b-row>
      <b-col cols="12" md="12">
        <br />
        <h5>
          <b
            >*Note : From the below form a new Organization and Admin user for
            that Organization will be created..!<br />
          </b>
        </h5>
        <h5>
          <b>
            *Note : Select Admin as a role while creating new Organization.
          </b>
        </h5>
      </b-col>
    </b-row>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group label="First Name">
              <b-form-input
                v-model="form.first_name"
                id="input-default"
                placeholder="First Name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Last Name">
              <b-form-input
                v-model="form.last_name"
                id="input-default"
                placeholder="Last Name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Contact No">
              <b-form-input
                type="number"
                v-model="form.mobile_number"
                id="input-default"
                placeholder="Contact No"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Email">
              <b-form-input
                v-model="form.email"
                id="input-default"
                placeholder="Email"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Password">
              <b-form-input
                v-model="form.password"
                id="input-default"
                placeholder="Password"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4"
            ><b-form-group label="User Role">
              <v-select
                :clearable="false"
                v-model="form.role_id"
                label="role_name"
                value="role_id"
                :reduce="(role) => role.role_id"
                placeholder="Select Role"
                :options="rolesList"
              /> </b-form-group
          ></b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Organization Status">
              <b-form-checkbox v-model="form.user_is_active" switch>{{
                form.user_is_active == true ? "Active" : "Disabled"
              }}</b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12">
            <div>
              <span
                ><b>Read:</b> Access to view data.Cannot do add & update!<br
              /></span>
              <span><b>Add:</b> Access to add data.<br /></span>
              <span><b>Update:</b> Access to update data.</span>
              <div class="container">
                <div class="row flex-nowrap overflow-auto">
                  <b-list-group horizontal>
                    <b-list-group-item
                      variant="primary"
                      v-for="(item, index) in form.role_permission"
                      :key="index"
                      ><h5>
                        <b>{{ item.name }}</b>
                      </h5>

                      <b-form-checkbox-group
                        v-model="item.action"
                        :options="optionsPermissionMain(item)"
                      ></b-form-checkbox-group>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </div>
            </div>
          </b-col>
          <b-col class="mt-3" md="6" offset-md="3">
            <b-button block variant="primary" @click="onSubmit"
              >Submit</b-button
            >
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";

import {
  AddUpdateOrgService,
  GetRolesListService,
} from "@/apiServices/MasterServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
export default {
  data() {
    return {
      form: {
        org_id: null,
        user_id: null,
        first_name: null,
        last_name: null,
        mobile_number: null,
        email: null,
        user_name: null,
        password: null,
        role_id: null,
        user_is_active: true,
        role_permission: [
          {
            action: [],
            subject: "countrymaster",
            name: "Country",
          },
          {
            action: [],
            subject: "citymaster",
            name: "City",
          },

          {
            action: [],
            subject: "seattypemaster",
            name: "Seat Type",
          },
          {
            action: [],
            subject: "languagemaster",
            name: "Language",
          },
          {
            action: [],
            subject: "genremaster",
            name: "Genre",
          },
          {
            action: [],
            subject: "currencymaster",
            name: "Currency",
          },
          {
            action: [],
            subject: "cinemamaster",
            name: "Cinema",
          },
          {
            action: [],
            subject: "eventlist",
            name: "Events",
          },
          {
            action: [],
            subject: "eventbanners",
            name: "Event Banners",
          },
          {
            action: [],
            subject: "eventseatlayout",
            name: " Seat Layout",
          },

          {
            action: [],
            subject: "transactionreport",
            name: "Booking Reports",
          },
          {
            action: [],
            subject: "reservationreport",
            name: "Reservation Report",
          },
          {
            action: [],
            subject: "userlist",
            name: "Users",
          },
          {
            action: [],
            subject: "roleList",
            name: "Roles",
          },

          {
            action: [],
            subject: "voucherlist",
            name: "Vouchers",
          },
          {
            action: [],
            subject: "contactuslist",
            name: "Contact Us",
          },
        ],
      },
      optionsPermission: [
        { text: "Read", value: "read" },
        { text: "Add", value: "create" },
        { text: "Update", value: "update" },
      ],
      rolesList: [],
    };
  },
  components: {
    vSelect,
  },
  mounted() {
    let userData = store.getters["user/getUserDetails"];
    if (userData.is_super_admin == "Y") {
    } else {
      window.location.replace("/");
    }
  },
  async beforeMount() {
    this.getRoleList();
  },
  methods: {
    optionsPermissionMain(item) {
      if (item) {
        return [
          { text: "Read", value: "read" },
          { text: "Add", value: "create" },
          { text: "Update", value: "update" },
        ];

        // item.subject == "countrymaster" || item.subject == "voucherlist"
        //   ? [{ text: "Read", value: "read" }]
        //   : item.subject == "citymaster" ||
        //     item.subject == "seattypemaster" ||
        //     item.subject == "languagemaster" ||
        //     item.subject == "genremaster" ||
        //     item.subject == "currencymaster" ||
        //     item.subject == "roleList"
        //   ? [
        //       { text: "Read", value: "read" },
        //       { text: "Add", value: "create" },
        //     ]
        //   : [
        //       { text: "Read", value: "read" },
        //       { text: "Add", value: "create" },
        //       { text: "Update", value: "update" },
        //     ];
      }
    },
    async onSubmit() {
      try {
        this.form.user_name = this.form.email;
        this.form.user_is_active = this.form.user_is_active == true ? "Y" : "N";
        const response = await AddUpdateOrgService(this.form);
        if (response.data.status) {
          this.$router.push({
            name: "organizationlist",
          });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "primary",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getRoleList() {
      try {
        const response = await GetRolesListService({
          limit: 1000,
        });

        if (response.data.status) {
          if (response.data.Records && response.data.Records.data) {
            this.rolesList = response.data.Records.data;
            this.rolesList = this.rolesList.filter((item) => item.role_id != 3);
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
